import type { ChangeEvent, FocusEvent } from 'react';
import { type ComponentProps, Fragment } from 'react';
import { Input as _Input, TextField } from 'react-aria-components';
import type { Control, FieldValues, Path, UseControllerReturn } from 'react-hook-form';
import { useController } from 'react-hook-form';
import { clsx } from 'clsx';
import { ButtonBase } from 'components/button-base';

import { useAppTranslate } from '../../hooks/useAppTranslate';
import { DeleteIcon } from '../../icons/DeleteIcon';
import { PlusIcon } from '../../icons/PlusIcons';

import styles from './InputField.module.css';

type _InputProps = ComponentProps<typeof _Input>;

type TextFieldProps = Omit<ComponentProps<typeof TextField>, 'onChange' | 'name'>;

type InputFieldProps<T extends FieldValues> = {
    onChange?: _InputProps['onChange'];
    control: Control<T, any>;
    name: {
        field: Path<T>;
        id: Path<T>;
    };
    isDuplicate?: boolean;
} & TextFieldProps;

interface InputButtonAddRemoveProps
    extends Omit<ComponentProps<typeof ButtonBase.Root>, 'children'> {
    isIncluded?: boolean;
}

interface InputFieldGroupProps extends ComponentProps<'div'> {
    variant?: 'default' | 'border';
}

export function InputFieldGroup({ variant = 'default', ...props }: InputFieldGroupProps) {
    return (
        <div
            className={clsx(styles.textField, {
                [styles.textFieldVariantDefault]: variant === 'default',
                [styles.textFieldVariantWithBorder]: variant === 'border',
            })}
            {...props}
        />
    );
}

export function InputField<T extends FieldValues>({
    onChange,
    onBlur,
    onFocus,
    isDisabled,
    autoFocus,
    control,
    name,
    isDuplicate,
}: InputFieldProps<T>) {
    const { t, t_agreena } = useAppTranslate();

    const controllerFieldName = useController({
        control,
        name: name.field,

        rules: {
            required: !isDisabled,
        },
    });

    const controllerFieldId = useController({
        control,
        name: name.id,
    });

    const onBlurHandler = (e: FocusEvent<Element, Element>, controller: UseControllerReturn<T>) => {
        controller.field.onBlur();
        onBlur?.(e);
    };

    const onChangeHandler = (value: string, controller: UseControllerReturn<T>) => {
        const evt = {
            target: {
                value,
            },
        } as unknown as ChangeEvent<HTMLInputElement>;

        controller.field.onChange?.(evt);
        onChange?.(evt);
    };

    const mutualProps = { isDisabled, onFocus };

    return (
        <Fragment>
            <TextField
                {...controllerFieldName.field}
                {...mutualProps}
                autoFocus={autoFocus}
                onBlur={(e) => onBlurHandler(e, controllerFieldName)}
                onChange={(value) => onChangeHandler(value, controllerFieldName)}
                isInvalid={controllerFieldName.fieldState.invalid}
            >
                <_Input className={styles.input} placeholder={t('hint_create_field_name')} />
            </TextField>

            <TextField
                {...controllerFieldId.field}
                {...mutualProps}
                onBlur={(e) => onBlurHandler(e, controllerFieldId)}
                onChange={controllerFieldId.field.onChange}
            >
                <_Input className={styles.input} placeholder={t('field_id')} />
            </TextField>

            {(isDuplicate || controllerFieldName.fieldState.error) && (
                <small className={styles.fieldError}>
                    {isDuplicate
                        ? t_agreena('duplicate_field_error')
                              .split('.')
                              .map((sentence, index) => (
                                  <span key={index}>
                                      {sentence.trim()}{' '}
                                      {index <
                                          t_agreena('duplicate_field_error').split('.').length -
                                              1 && <br />}
                                  </span>
                              ))
                        : t('field_create_name_validation')}
                </small>
            )}
        </Fragment>
    );
}

export function InputFieldButtonAddRemove({ isIncluded, ...props }: InputButtonAddRemoveProps) {
    return (
        <ButtonBase.Root
            className={!isIncluded ? styles.buttonRemove : styles.buttonAdd}
            {...props}
        >
            <ButtonBase.Icon variant="18">
                {isIncluded ? <DeleteIcon /> : <PlusIcon />}
            </ButtonBase.Icon>
        </ButtonBase.Root>
    );
}
