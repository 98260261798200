import { useEffect } from 'react';
import {
    createBrowserRouter,
    Navigate,
    Outlet,
    type RouteObject,
    RouterProvider,
    useLocation,
} from 'react-router-dom';
import { AgreenaFarmLoader } from '@notes-web/agreena';
import AgreenaAuth from '@notes-web/agreena/src/app/AgreenaAuth';
import { analytics } from '@notes-web/analytics';
import { AgreenaAuthenticatedContainer, App } from '@notes-web/app';
import AuthorizationCompletion from 'auth/AurthorizationCompletion';
import UserDeleteSuccess from 'auth/general/UserDeleteSuccess';
import { NuqsAdapter } from 'nuqs/adapters/react-router';
import FarmOverview from 'view/FarmOverview';

import { APP_ROUTES } from '../types';

import { addFieldsRoutes } from './add-fields.routes';
import { createFarmRoutes } from './createFarm';
import { detectBoundariesRoutes } from './detect-boundaries';
import { fieldsRoutes } from './fields.routes';
import { onboardingRoutes } from './onboarding.routes';
import { rpaRoutes } from './rpa.routes';

const Track = () => {
    const location = useLocation();

    useEffect(() => {
        analytics.page();
    }, [location.pathname]);

    return <Outlet />;
};

export const agreenaRoutes: RouteObject[] = [
    {
        path: APP_ROUTES.Root,
        element: <App />,

        children: [
            {
                path: '',
                element: <Track />,
                children: [
                    {
                        path: 'agreena-auth',
                        element: <AgreenaAuth />,
                    },
                    {
                        path: 'auth/complete',
                        element: <AuthorizationCompletion />,
                    },
                    {
                        path: 'account/delete/success',
                        element: <UserDeleteSuccess />,
                    },

                    {
                        path: APP_ROUTES.Root,
                        element: <AgreenaAuthenticatedContainer />,

                        children: [
                            createFarmRoutes,

                            {
                                path: APP_ROUTES.Farms.Farm,
                                element: <AgreenaFarmLoader />,

                                children: [
                                    {
                                        element: <FarmOverview />,

                                        children: [
                                            onboardingRoutes,
                                            addFieldsRoutes,
                                            fieldsRoutes,
                                            rpaRoutes,
                                            // johnDeereRoutes,
                                            ...detectBoundariesRoutes,
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },

            // Catch-all route to handle unmatched routes within AuthenticatedContainer
            {
                path: '*',
                element: <Navigate to={APP_ROUTES.Root} replace />,
            },
        ],
    },
];

const router = createBrowserRouter(agreenaRoutes);

export function AppRouterAgreena() {
    return (
        <NuqsAdapter>
            <RouterProvider router={router} />
        </NuqsAdapter>
    );
}
