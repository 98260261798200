import type { ComponentProps } from 'react';
import clsx from 'clsx';
import { ButtonBase } from 'components/button-base';

import { DeleteIcon } from '../../../icons/DeleteIcon';

import styles from './ButtonDelete.module.css';

type ButtonDeleteProps = Omit<ComponentProps<(typeof ButtonBase)['Root']>, 'children'>;

function ButtonDelete({ className, ...props }: ButtonDeleteProps) {
    return (
        <ButtonBase.Root className={clsx(styles.buttonDelete, className)} {...props}>
            <ButtonBase.Icon variant="18">
                <DeleteIcon />
            </ButtonBase.Icon>
        </ButtonBase.Root>
    );
}

export { ButtonDelete };
