import { analytics } from '@notes-web/analytics';
import { usePageParams } from 'hooks/usePageParamas';

import { useAgreenaToken } from './useAgreenaToken';

const track = analytics.track;

const useAgreenaTracking = () => {
    const { farmUuid, fieldUuid } = usePageParams();
    const { agreenaToken } = useAgreenaToken();

    const trackReturnToAgreena = () => {
        track('Return to agreena', {
            farmUuid,
            agreena: {
                farmId: agreenaToken?.farmId,
                farmName: agreenaToken?.farmName,
                email: agreenaToken?.email,
            },
        });
    };

    const trackCreateField = (fieldUuid: string) => {
        track('Field created', {
            farmUuid,
            fieldUuid,
        });
    };

    const trackUpdateField = () => {
        track('Field updated', {
            farmUuid,
            fieldUuid,
        });
    };

    const trackExportFields = () => {
        track('Export fields', {
            farmUuid,
        });
    };

    return { trackUpdateField, trackExportFields, trackCreateField, trackReturnToAgreena };
};

export { useAgreenaTracking };
