import { Fragment } from 'react/jsx-runtime';
import { useNavigate } from 'react-router-dom';
import { ButtonBase } from 'components/button-base';
import type Field from 'fields/Field';
import { getFieldWorkedArea } from 'fields/Field';
import { useCurrentYearSelector } from 'hooks/selectors';
import { useStartEditingField } from 'hooks/useStartEditingField';
import FormattedArea from 'view/units/FormattedArea';

import { useEditFieldNameParams } from '../../hooks/useEditFieldNameParams';
import { EditIcon } from '../../icons/EditIcon';
import { ButtonDelete } from '../Buttons/ButtonDelete';

import styles from './FieldItem.module.css';

interface FieldProps {
    field: Field;
    onDelete: () => void;
}

function FieldItem({ field, onDelete }: FieldProps) {
    const navigate = useNavigate();
    const { currentYear } = useCurrentYearSelector();
    const { startEditingField } = useStartEditingField();
    const { setEditFieldName } = useEditFieldNameParams();

    const area = getFieldWorkedArea(field, currentYear);

    const onEditHandler = () => {
        setEditFieldName(field.name);
        startEditingField(field);
        navigate(`${field.get('uuid')}/edit`);
    };

    return (
        <Fragment>
            <span className={styles.fieldName}>{field.name}</span>
            <span className={styles.fieldId}>{field.fieldId}</span>
            <span className={styles.fieldArea}>
                <FormattedArea areaSqm={area} withUnit />
            </span>

            <ButtonBase.Root
                className={`${styles.buttonAction} ${styles.buttonEdit}`}
                onPress={onEditHandler}
            >
                <ButtonBase.Icon variant="16">
                    <EditIcon />
                </ButtonBase.Icon>
            </ButtonBase.Root>

            <ButtonDelete
                className={`${styles.buttonAction} ${styles.buttonDelete}`}
                onPress={onDelete}
            />
        </Fragment>
    );
}

export { FieldItem };
