import { Link } from 'react-router-dom';
import { usePageParams } from 'hooks/usePageParamas';

import { detectBoundariesLogo, rpaLogo } from '../../assets';
import { useAppTranslate } from '../../hooks/useAppTranslate';
import { Sidebar } from '../Sidebar';

import styles from './AddFields.module.css';

const addFieldsLinks = [
    {
        path: 'boundaries',
        title: 'add_fields_detect_boundaries_title',
        description: 'add_fields_detect_boundaries_description',
        logo: detectBoundariesLogo,
        disabled: false,
    },
    {
        path: 'rpa',
        title: 'add_fields_rpa_title',
        description: 'add_fields_rpa_description',
        logo: rpaLogo,
        disabled: false,
    },
    // {
    //     path: 'john-deere',
    //     title: 'add_fields_john_deere_title',
    //     description: 'add_fields_john_deere_description',
    //     logo: johnDeereLogo,
    //     disabled: false,
    // },
];

function AddFields() {
    const { t_agreena } = useAppTranslate();
    const { farmUuid } = usePageParams();
    const path = (path: string) => `/farms/${farmUuid}/${path}`;

    return (
        <Sidebar.Root>
            <Sidebar.Body>
                <Sidebar.Header>
                    <Sidebar.Heading>{t_agreena('add_fields_heading')}</Sidebar.Heading>
                </Sidebar.Header>

                <Sidebar.Card>
                    <div className={styles.linksHeading}>
                        {t_agreena('add_fields_heading_options')}
                    </div>

                    <div className={styles.linksWrapper}>
                        {addFieldsLinks.map((item) => (
                            <Link
                                key={item.path}
                                to={path(item.path)}
                                className={styles.link}
                                aria-disabled={item.disabled}
                            >
                                <div className={styles.linkImageContainer}>
                                    <img
                                        className={styles.linkImage}
                                        src={item.logo}
                                        alt={t_agreena(item.title)}
                                    />
                                </div>

                                <div className={styles.linkText}>
                                    <Sidebar.Title>{t_agreena(item.title)}</Sidebar.Title>
                                    <Sidebar.Description>
                                        {t_agreena(item.description)}
                                    </Sidebar.Description>
                                </div>
                            </Link>
                        ))}
                    </div>
                </Sidebar.Card>
            </Sidebar.Body>
        </Sidebar.Root>
    );
}

export { AddFields };
