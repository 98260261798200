import { useNavigate } from 'react-router-dom';
import { useDownloadFieldBoundry } from 'hooks/useDownloadFieldBoundry';
import { useFieldUsagesWithFields } from 'hooks/useFieldUsagesWithFields';

import { useAgreenaTracking } from '../../hooks/useAgreenaTracking';
import { useAppTranslate } from '../../hooks/useAppTranslate';
import { Sidebar } from '../Sidebar';

import { FieldsList } from './FieldsList';

function Fields() {
    const navigate = useNavigate();
    const { trackExportFields } = useAgreenaTracking();
    const { handleShpDownload } = useDownloadFieldBoundry();

    const { t_agreena } = useAppTranslate();

    const { fieldUsagesWithFields } = useFieldUsagesWithFields();
    const firstActiveField = fieldUsagesWithFields.active.get(0);
    const fields = firstActiveField ? firstActiveField.fields : undefined;

    const onAddMoreFields = () => {
        navigate('../add-fields');
    };

    const onDownload = () => {
        trackExportFields();
        handleShpDownload();
        navigate('completion', { state: { completion: true } });
    };

    return (
        <Sidebar.Root>
            <Sidebar.Body>
                <Sidebar.Header>
                    <Sidebar.Heading>{t_agreena('fields_page_heading')}</Sidebar.Heading>
                </Sidebar.Header>

                {fields && (
                    <Sidebar.Card>
                        <FieldsList fields={fields} />
                    </Sidebar.Card>
                )}
            </Sidebar.Body>

            <Sidebar.Footer>
                <Sidebar.ButtonFooter variant="outline-primary" onPress={onAddMoreFields}>
                    {t_agreena('fields_view_add_more')}
                </Sidebar.ButtonFooter>

                <Sidebar.ButtonFooter onPress={onDownload}>
                    {t_agreena('fields_view_export_fields')}
                </Sidebar.ButtonFooter>
            </Sidebar.Footer>
        </Sidebar.Root>
    );
}

export { Fields };
