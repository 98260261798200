import type { RouteObject } from 'react-router-dom';
import { RPAImportContainer } from '@notes-web/agreena';
import ErrorMessage from 'view/ErrorMessage';

import { APP_ROUTES } from '../types';

const rpaRoutes: RouteObject = {
    path: APP_ROUTES.Fields.RPA,
    element: <RPAImportContainer />,
    errorElement: (
        <ErrorMessage className="p-5">
            Something went wrong when importing boundaries, please refresh the page.
        </ErrorMessage>
    ),
};

export { rpaRoutes };
