import { useNavigate } from 'react-router-dom';
import { usePageParams } from 'hooks/usePageParamas';

import { useAppTranslate } from '../../hooks/useAppTranslate';
import { Sidebar } from '../Sidebar';
import { Timeline } from '../Timeline';

const onboardingData = [
    {
        heading: 'onboarding_step_1_heading',
        list: [
            'onboarding_step_1_list_1',
            'onboarding_step_1_list_2',
            'onboarding_step_1_list_3',
            'onboarding_step_1_list_4',
        ],
    },
    {
        heading: 'onboarding_step_2_heading',
        description: 'onboarding_step_2_description',
    },
    { heading: 'onboarding_step_3_heading' },
    {
        heading: 'onboarding_step_4_heading',
        description: 'onboarding_step_4_description',
    },
];

function Onboarding() {
    const { t, t_agreena } = useAppTranslate();
    const navigate = useNavigate();
    const { farmUuid } = usePageParams();

    const onContinueHandler = () => navigate(`/farms/${farmUuid}/add-fields`);

    return (
        <Sidebar.Root>
            <Sidebar.Body>
                <Sidebar.Header>
                    <Sidebar.Heading>{t_agreena('fields_page_heading')}</Sidebar.Heading>

                    <Sidebar.Description>
                        {t_agreena('onboarding_page_description')}
                    </Sidebar.Description>
                </Sidebar.Header>

                <Sidebar.Card>
                    <Timeline.Data data={onboardingData} />
                </Sidebar.Card>
            </Sidebar.Body>

            <Sidebar.Footer>
                <Sidebar.ButtonFooter onPress={onContinueHandler}>
                    {t('continue')}
                </Sidebar.ButtonFooter>
            </Sidebar.Footer>
        </Sidebar.Root>
    );
}

export { Onboarding };
