import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { addListener } from '@reduxjs/toolkit';
import { stopDrawingAction } from 'store/draw.slice';
import { useAppDispatch } from 'system/store';

import { useAppTranslate } from '../../hooks/useAppTranslate';
import { Sidebar } from '../Sidebar';

function DrawBoundaries() {
    const { t_agreena } = useAppTranslate();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = dispatch(
            addListener({
                actionCreator: stopDrawingAction,
                effect: () => {
                    navigate('save');
                },
            })
        );
        return unsubscribe;
    }, [navigate, dispatch]);

    return (
        <Sidebar.Root>
            <Sidebar.Body>
                <Sidebar.Navigation />

                <Sidebar.Header>
                    <Sidebar.Heading>{t_agreena('draw_area_heading')}</Sidebar.Heading>
                    <Sidebar.Description>{t_agreena('draw_area_description')}</Sidebar.Description>
                </Sidebar.Header>
            </Sidebar.Body>
        </Sidebar.Root>
    );
}

export { DrawBoundaries };
