import { useTranslation } from 'react-i18next';
import { useIsDrawingBlocked } from 'hooks/useIsDrawingBlocked';

import { Sidebar } from '../Sidebar';

import styles from './DrawingBlockedCard.module.css';

function DrawingBlockedCard() {
    const { t } = useTranslation();
    const { isDrawingBlocked } = useIsDrawingBlocked();
    return (
        isDrawingBlocked && (
            <Sidebar.Card className={styles.cardMessage}>
                <Sidebar.Description>{t('digifarm_zoom_level')}</Sidebar.Description>
            </Sidebar.Card>
        )
    );
}

export { DrawingBlockedCard };
