import { Outlet, type RouteObject } from 'react-router-dom';
import {
    DownloadCompletion,
    Fields,
    ProtectedCompletion,
    SaveDrawBoundaries,
} from '@notes-web/agreena';

import { APP_ROUTES } from '../types';

export const fieldsRoutes: RouteObject = {
    path: APP_ROUTES.Fields.Root,
    element: <Outlet />,

    children: [
        { index: true, element: <Fields /> },

        {
            element: <ProtectedCompletion />,

            children: [
                { path: APP_ROUTES.Fields.DownloadCompletion, element: <DownloadCompletion /> },
            ],
        },

        {
            path: APP_ROUTES.Fields.Field,
            element: <Outlet />,
            children: [
                {
                    index: true,
                    element: <SaveDrawBoundaries />,
                },

                {
                    path: APP_ROUTES.Fields.Edit,
                    element: <SaveDrawBoundaries />,
                },
            ],
        },
    ],
};
