import { Fragment } from 'react/jsx-runtime';
import { Form } from 'react-aria-components';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import type Field from 'fields/Field';
import { useCreateField } from 'fields/sidebar/field-hooks';
import { useCurrentField } from 'hooks/selectors';
import { useCheckFieldNamesDuplicates } from 'hooks/useCheckFieldnamesDuplicates';
import { useClearEditingGeoFeatureCollection } from 'hooks/useClearEditingGeoFeatureCollection';
import { useEditingGeoFeatureCollection } from 'hooks/useEditingGeoFeatureCollection';
import { useRemoveFieldBoundary } from 'hooks/useRemoveFieldBoundary';
import { useShapeAreaValue } from 'hooks/useShapeAreaValue';
import { useUpdateEditField } from 'hooks/useUpdateEditField';

import { useAgreenaTracking } from '../../hooks/useAgreenaTracking';
import { useAppTranslate } from '../../hooks/useAppTranslate';
import { useEditFieldMap } from '../../hooks/useClickMapFieldEffect';
import { useEditFieldNameParams } from '../../hooks/useEditFieldNameParams';
import { useEditingNewFieldEffect } from '../../hooks/useEditingNewFieldEffect';
import { useZoomToCurrentFeature } from '../../hooks/useZoomToCurrentFeature';
import { ButtonDelete } from '../Buttons/ButtonDelete';
import { DrawBoundaryOptions } from '../DrawBoundaryOptions';
import { DrawingBlockedCard } from '../DrawingBlockedCard';
import { InputField } from '../InputField';
import { PromptBlocker } from '../PromptBlocker';
import { Sidebar } from '../Sidebar';

import { useHasEditingFeaturesOrField } from './useHasEditingFeaturesOrField';

import styles from './SaveDrawBoundaries.module.css';

interface FormValueSchema {
    name: string;
    id: string;
}

function DeleteField() {
    const { onRemoveBoundary } = useRemoveFieldBoundary();

    const onRemovehandler = () => {
        onRemoveBoundary();
    };

    return <ButtonDelete onPress={onRemovehandler} />;
}

function SaveDrawBoundariesFooter() {
    const { t_agreena } = useAppTranslate();

    const navigate = useNavigate();
    const methods = useFormContext<FormValueSchema>();
    const { handleSubmit, formState, watch } = methods;
    const name = watch('name');
    const { editFieldName, setEditFieldName } = useEditFieldNameParams();
    const { isDuplicate } = useCheckFieldNamesDuplicates();
    const { hasEditingFeaturesOrField } = useHasEditingFeaturesOrField();

    const { trackUpdateField, trackCreateField } = useAgreenaTracking();

    const { onCreateField, isPending: isCreateFieldPending } = useCreateField();
    const { onUpdateField, isPending: isUpdateFieldPending } = useUpdateEditField();
    const { editingGeoFeatureCollection } = useEditingGeoFeatureCollection();
    const { currentField } = useCurrentField();
    const { clearEditingGeoFeatureCollection } = useClearEditingGeoFeatureCollection();
    const updatedField = currentField?.set('geoJson', editingGeoFeatureCollection);

    const isButtonDisabled =
        isUpdateFieldPending ||
        isCreateFieldPending ||
        formState.isSubmitting ||
        formState.isLoading ||
        !hasEditingFeaturesOrField ||
        (name !== editFieldName && isDuplicate({ name }));

    const onSaveFieldHandler = (values: FormValueSchema) => {
        const navigateToFields = () => navigate('../../../fields');

        if (updatedField) {
            onUpdateField({
                field: updatedField,
                values: values,
                onSave: () => {
                    trackUpdateField();
                    setEditFieldName(null);
                    navigateToFields();
                },
            });
        } else {
            if (editingGeoFeatureCollection !== null) {
                onCreateField({
                    name: values.name,
                    fieldId: values.id,
                    geoJson: editingGeoFeatureCollection,
                    onSave: (field: Field) => {
                        trackCreateField(field.uuid);
                        navigateToFields();
                    },
                });
            }
        }
    };

    const onSaveAndContinueFieldHandler = (values: FormValueSchema) => {
        if (updatedField) {
            onUpdateField({
                field: updatedField,
                values,
                onSave: () => {
                    clearEditingGeoFeatureCollection();
                    methods.setFocus('name');
                    methods.reset({ name: '', id: '' });
                    navigate('../../../boundaries/draw/save');
                },
            });
        } else {
            if (editingGeoFeatureCollection !== null) {
                onCreateField({
                    name: values.name,
                    fieldId: values.id,
                    geoJson: editingGeoFeatureCollection,
                    onSave: () => {
                        clearEditingGeoFeatureCollection();
                        methods.setFocus('name');
                        methods.reset({ name: '', id: '' });
                    },
                });
            }
        }
    };

    return (
        <Sidebar.Footer>
            {!editFieldName && (
                <Sidebar.ButtonFooter
                    variant="outline-primary"
                    onPress={() => handleSubmit(onSaveAndContinueFieldHandler)()}
                    isDisabled={isButtonDisabled}
                >
                    {t_agreena('save_draw_boundaries_save_and_draw')}
                </Sidebar.ButtonFooter>
            )}

            <Sidebar.ButtonFooter
                onPress={() => handleSubmit(onSaveFieldHandler)()}
                isDisabled={isButtonDisabled}
            >
                {t_agreena('save_draw_boundaries_save_field')}
            </Sidebar.ButtonFooter>
        </Sidebar.Footer>
    );
}

function SaveDrawBoundaries() {
    const { t_agreena } = useAppTranslate();
    const { shapeFieldArea } = useShapeAreaValue();
    const { currentField } = useCurrentField();
    const { isDuplicate } = useCheckFieldNamesDuplicates();
    const { editFieldName } = useEditFieldNameParams();
    const { hasEditingFeaturesOrField } = useHasEditingFeaturesOrField();

    const formDefaultValues = {
        name: currentField?.get('name') ?? '',
        id: currentField?.get('fieldId') ?? '',
    };

    const methods = useForm<FormValueSchema>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        defaultValues: formDefaultValues,
    });

    const { control, watch, formState } = methods;
    const name = watch('name');

    const isDuplicateName =
        editFieldName !== name &&
        isDuplicate({
            name,
        });

    useEditFieldMap();
    useZoomToCurrentFeature();
    useEditingNewFieldEffect();

    return (
        <Sidebar.Root>
            <FormProvider {...methods}>
                <Sidebar.Body>
                    <Sidebar.Navigation />

                    <Sidebar.Header>
                        <div>
                            <Sidebar.Heading>
                                {t_agreena('save_draw_boundaries_heading')}
                            </Sidebar.Heading>
                            <Sidebar.Description>
                                {t_agreena('save_draw_boundaries_description')}
                            </Sidebar.Description>
                        </div>
                    </Sidebar.Header>

                    {}

                    {!hasEditingFeaturesOrField && (
                        <Fragment>
                            <DrawingBlockedCard />
                            <DrawBoundaryOptions />
                        </Fragment>
                    )}

                    {hasEditingFeaturesOrField && (
                        <Sidebar.Card className={styles.mappedValue}>
                            <Sidebar.Title>
                                {t_agreena('save_draw_boundaries_mapped')}
                            </Sidebar.Title>
                            <Sidebar.Description>{shapeFieldArea}</Sidebar.Description>
                            <DeleteField />
                        </Sidebar.Card>
                    )}

                    <Sidebar.Card>
                        <Form autoComplete="off">
                            <InputField.Group>
                                <InputField.Root<FormValueSchema>
                                    name={{
                                        field: 'name',
                                        id: 'id',
                                    }}
                                    autoFocus
                                    control={control}
                                    isDuplicate={isDuplicateName}
                                />
                            </InputField.Group>
                        </Form>
                    </Sidebar.Card>
                </Sidebar.Body>

                <SaveDrawBoundariesFooter />
            </FormProvider>

            <PromptBlocker.Root isBlocked={formState.isDirty && !formState.isSubmitSuccessful}>
                <PromptBlocker.ButtonsGroup>
                    <PromptBlocker.ButtonLeave />
                    <PromptBlocker.ButtonStay />
                </PromptBlocker.ButtonsGroup>
            </PromptBlocker.Root>
        </Sidebar.Root>
    );
}

export { SaveDrawBoundaries };
