import { useAppTranslate } from '../../hooks/useAppTranslate';
import { useReturnToAgreena } from '../../hooks/useReturnToAgreena';
import { Sidebar } from '../Sidebar';

function DownloadCompletion() {
    const { t_agreena } = useAppTranslate();
    const { returnToAgreenaComplete } = useReturnToAgreena();

    return (
        <Sidebar.Root>
            <Sidebar.Body>
                <Sidebar.Header>
                    <Sidebar.Heading>{t_agreena('completion_heading')}</Sidebar.Heading>
                </Sidebar.Header>

                <Sidebar.Card>
                    <Sidebar.Description>{t_agreena('completion_description')}</Sidebar.Description>
                </Sidebar.Card>
            </Sidebar.Body>

            <Sidebar.Footer>
                <Sidebar.ButtonFooter onPress={returnToAgreenaComplete}>
                    {t_agreena('completion_return_to_agreena')}
                </Sidebar.ButtonFooter>
            </Sidebar.Footer>
        </Sidebar.Root>
    );
}

export { DownloadCompletion };
