import { useTranslation } from 'react-i18next';
import { ButtonBase } from 'components/button-base';
import { useNavigateBack } from 'hooks/useNavigateBack';

import { ChevronIcon } from '../../../icons/Chevron';

import styles from './ButtonBack.module.css';

interface ButtonBackProps {
    onBack?: () => void;
}

function ButtonBack({ onBack }: ButtonBackProps) {
    const { t } = useTranslation();
    const { onNavigateBackHandler } = useNavigateBack();

    const onPressHandler = () => {
        onNavigateBackHandler();
        onBack?.();
    };

    return (
        <ButtonBase.Root className={styles.buttonBack} onPress={onPressHandler}>
            <span className={styles.buttonBackIcon}>
                <ChevronIcon />
            </span>
            <span>{t('back')}</span>
        </ButtonBase.Root>
    );
}

export { ButtonBack };
