import { useEffect, useReducer } from 'react';
import { useSelector } from 'react-redux';
import type { Farm } from '@fieldmargin/webapp-farms';
import { useCreateFarmMutation } from '@notes-web/api';
import { getUserLocationApi } from 'farms/create/create-farm-api';
import {
    basemapClickReducer,
    featureClickReducer,
    geoLocationLookupReducer,
    searchedPlacesReducer,
} from 'farms/create/create-farm-utils';
import CreateFarmState from 'farms/create/CreateFarmState';
import { addFarm } from 'farms/farms-state';
import { refreshFirebaseToken } from 'lib/firebase/firebase-connection';
import { useAppDispatch } from 'system/store';
import { selectUserAsFarmOwner } from 'users/user-state';

import { VITE_APP_NAME } from '../../env';

const reducer = (state: CreateFarmState, action: { type: string; payload: any }) => {
    switch (action.type) {
        case 'userLocation':
            return state.set('userLocation', action.payload);
        case 'searchedPlaces':
            return searchedPlacesReducer(action.payload, state);
        case 'geoLocationLookup':
            return geoLocationLookupReducer(action.payload, state);
        case 'basemapClick':
            return basemapClickReducer(action.payload, state);
        case 'featureClick':
            return featureClickReducer(action.payload, state);
        case 'setFarmLocationError':
            return state.set('farmLocationFieldError', action.payload);
        default:
            throw new Error(`Unknown create farm state action: ${action.type}`);
    }
};

interface UseCreateFarmProps {
    onSuccess?: (farm: Farm) => void;
}

interface CreateFarmFormSchema {
    name: string;
    externalId: number | null;
}

const useCreateFarm = ({ onSuccess }: UseCreateFarmProps) => {
    const dispatchRedux = useAppDispatch();
    const [createFarm, { isLoading, isError }] = useCreateFarmMutation();

    const [state, dispatch] = useReducer(reducer, CreateFarmState({}));

    const userFarmUser = useSelector(selectUserAsFarmOwner);

    const handleSubmit = async ({ name, externalId }: CreateFarmFormSchema) => {
        if (!state.farmLocation) {
            dispatch({ type: 'setFarmLocationError', payload: true });
        } else if (name) {
            const extraAgreenaProps =
                VITE_APP_NAME === 'agreena'
                    ? {
                          externalId,
                          externalIdSource: 'AGREENA' as const,
                      }
                    : {};
            try {
                const { data: farm } = await createFarm({
                    farmName: name,
                    farmLocation: state.farmLocation,
                    ...extraAgreenaProps,
                });

                await refreshFirebaseToken();

                if (farm) {
                    const createdFarm = userFarmUser ? { ...farm, owners: [userFarmUser] } : farm;
                    dispatchRedux(addFarm(createdFarm));
                    onSuccess?.(createdFarm);
                }
            } catch (_err) {
                //
            }
        }
    };

    useEffect(() => {
        const getUserLocation = async () => {
            try {
                const userLocation = await getUserLocationApi();
                dispatch({ type: 'userLocation', payload: userLocation });
            } catch (_e) {
                // Do nothing if this fails, user will have to find their place themselves.
            }
        };
        getUserLocation();
    }, []);

    return {
        isPending: isLoading,
        isError,
        onCreateFarm: handleSubmit,
        state,
        dispatch,
    };
};

export { useCreateFarm };
